import * as React from "react";
import {createRef, useContext, useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import Navigation from "../components/Navigation";
import { gsap, Expo, Power1, Quint } from "gsap";
import {graphql, Link, navigate} from "gatsby";
import {createRefAndSetFunction} from "../components/Helpers/RefHelper";
import Logo from "../components/Logo";
import {useMediaQuery} from "react-responsive";
import Breadcrumb from "../components/Breadcrumb";
import {off, on, trigger} from "../components/Events";
import Cursor from "../components/Cursor";
import {GlobalContext} from "../components/Context";
import {getPageTitle} from "../components/Helpers/Meta";
import Fade from "../transitions/Fade";
import Tilt from 'react-parallax-tilt';
import ScrollIndicator from "../components/ScrollIndicator";

interface TeamPhoto {
    src: string,
    ref: React.MutableRefObject<any>,
    placed: boolean,
    width: number,
    height: number,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    scale: number,
    slug: string,
    firstname: string,
    jobTitle: string
}


export const TEAM_OVERVIEW_QUERY = graphql`
  query TeamOverviewPageQuery($id: String!) {
    content: contentfulTeamOverviewPage(
      id: { eq: $id }
    ) {
      metatitle 
      teamMembers {
        firstname
        function
        slug
        profilePicture {
            gatsbyImageData(width: 180, placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
            title
          }
      }
    }
  }
`;
const TeamOverviewTemplate = ({data}) => {

    const {
        content: {
            metatitle: metatitle,
            teamMembers: teamMembers
        }
    } = data;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' })
    const EXPLODE = 1;
    const IMPLODE = 0;
    const isWindowDefined = typeof window !== 'undefined';

    const [teamPhotos, _setTeamPhotos] = useState<TeamPhoto[]>([]);

    const requestRef = useRef(null)
    const heroTextRef = useRef(null);
    const isTabletOrMobileRef = useRef(null);

    const [windowSize, setWindowSize] = createRefAndSetFunction(isWindowDefined
        ? {width:  window.innerWidth, height: window.innerHeight}
        : {width: 0, height: 0});

    const [mousePosition, setMousePosition] = createRefAndSetFunction({ x: 0, y: 0 })
    const [lastMousePosition, setLastMousePosition] = createRefAndSetFunction({ x: 0, y: 0 })
    const [cacheMousePosition, setCacheMousePosition] = createRefAndSetFunction({ x: 0, y: 0 })
    const [zIndexVal, setZIndexVal] = createRefAndSetFunction(1)
    const [imgPosition, setImgPosition] = createRefAndSetFunction(0)
    const [explodeOrImplode, setExplodeOrImplode] = createRefAndSetFunction(IMPLODE)
    const [preventHover, setPreventHover] = createRefAndSetFunction(false)

    const teamPhotosRef = useRef(teamPhotos);
    const setTeamPhotos = (data) => {
        teamPhotosRef.current = data;
        _setTeamPhotos(data);
    };

    // mouse distance required to show the next image
    const threshold = 200;

    const lerp = (a, b, n) => (1 - n) * a + n * b;
    const getRandomFloat = (min, max) => (Math.random() * (max - min) + min).toFixed(2);

    const getMouseDistance = () => {
        return Math.hypot(lastMousePosition.current.x-mousePosition.current.x, lastMousePosition.current.y-mousePosition.current.y)
    }

    const renderTrailImageAnimation = () => {
        if (preventHover.current || explodeOrImplode.current !== IMPLODE) return;

        let distance = getMouseDistance();

        // cache previous mouse position
        setCacheMousePosition({
            x: lerp(cacheMousePosition.current.x || mousePosition.current.x, mousePosition.current.x, 0.1),
            y: lerp(cacheMousePosition.current.y || mousePosition.current.y, mousePosition.current.y, 0.1)
        })

        if (distance > threshold) {
            showNextImage();

            setZIndexVal(zIndexVal.current + 1);
            setImgPosition(imgPosition.current < teamPhotosRef.current.length-1 ? imgPosition.current+1 : 0);

            setLastMousePosition(mousePosition.current)
        }

        let isIdle = true;
        for (let teamPhoto of teamPhotosRef.current) {
            const imgRef = teamPhoto.ref;
            if (!imgRef || !imgRef.current || !imgRef.current.style) continue;
            if (gsap.isTweening(imgRef.current) || imgRef.current.style.opacity != 0) {
                isIdle = false;
                break;
            }
        }

        // reset z-index initial value
        if ( isIdle && zIndexVal.current !== 1 ) {
            setZIndexVal(1);
        }

    }

    const renderHoverImageAnimation = () => {
        if (explodeOrImplode.current !== EXPLODE) return;

        const distance = getMouseDistance();
        if (distance < 20) return;

        // Update the mouse position
        setLastMousePosition(mousePosition.current)

        // Update the zIndex value
        setZIndexVal(zIndexVal.current + 1);

        const currentMousePosition = mousePosition.current;
        teamPhotosRef.current.map((teamPhoto) => {
            const imageRef = teamPhoto.ref;
            if (!imageRef || !imageRef.current) return;

            const imageRect = imageRef.current.getBoundingClientRect();
            if ((currentMousePosition.x >= imageRect.x && currentMousePosition.x <= (imageRect.x + imageRect.width))
                && (currentMousePosition.y >= imageRect.y && currentMousePosition.y <= (imageRect.y + imageRect.height))) {

                teamPhotosRef.current.map((teamPhoto) => {
                    const otherImageRef = teamPhoto.ref;
                    if (!otherImageRef || !otherImageRef.current) return;
                    if (otherImageRef.current === imageRef.current) return;

                    const otherNameContainerTarget = otherImageRef.current.getElementsByClassName('c-team-overview--photo-name-container')[0];
                    if (otherNameContainerTarget) {
                        otherNameContainerTarget.classList.remove('active')
                    }
                    gsap.timeline()
                        .to(otherImageRef.current, {
                            opacity: 0.5,
                            duration: 0.6,
                            scale: 1
                        })
                });

                gsap.killTweensOf(nameContainerTarget)
                gsap.timeline()
                    .set(imageRef.current, {
                        zIndex: zIndexVal.current
                    }).to(imageRef.current, {
                        opacity: 1,
                        duration: 0.6,
                        scale: 1.3
                    })

                const nameContainerTarget = imageRef.current.getElementsByClassName('c-team-overview--photo-name-container')[0];
                if(nameContainerTarget) {
                    nameContainerTarget.classList.add('active')
                }
            }
        });
    }

    const render = () => {
        if (isTabletOrMobileRef.current) return
        renderHoverImageAnimation();
        renderTrailImageAnimation();
        // loop..
        requestRef.current = requestAnimationFrame(render);
    };

    function intersects(a : TeamPhoto, b : TeamPhoto) {
        const pad = -20;
        return !(b.x1 > a.x2 + pad ||
            b.x2 < a.x1 - pad ||
            b.y1 > a.y2 + pad ||
            b.y2 < a.y1 - pad);
    }

    const animateExplodeTeamPhoto = (teamPhoto: TeamPhoto) => {
        const imgRef = teamPhoto.ref;
        if (!imgRef || !imgRef.current) return;

        const width = teamPhoto.width * teamPhoto.scale;
        const height = teamPhoto.height * teamPhoto.scale;

        gsap.killTweensOf(imgRef.current);
        gsap.timeline()
            .set(imgRef.current, {
                // startAt: {opacity: 0},
                opacity: 0,
                rotation: 0,
                zIndex: zIndexVal.current,
                x:  mousePosition.current.x - width/2,
                y: mousePosition.current.y - height/2,
            }, 0)
            .to(imgRef.current, {
                opacity: 1,
                duration: 1,
                x: teamPhoto.x1,
                y: teamPhoto.y1,
                width: width,
                height: height
            })
    }

    const placeTeamPhoto = (teamPhoto: TeamPhoto, stackSize = 0) => {
        const imgRef = teamPhoto.ref;
        if (!imgRef || !imgRef.current) return;
        if (stackSize > 50) {
            teamPhoto.placed = true;
            console.error('Failed to place team photo stack size of 20 exceeded');
            // requestAnimationFrame(() => {
            //     placeTeamPhoto(teamPhoto)
            // });
            return;
        }

        const imgRect = imgRef.current.getBoundingClientRect();
        const scale = parseFloat(getRandomFloat(0.8, 1));

        const width = teamPhoto.width !== null ? teamPhoto.width : imgRect.width;
        const height = teamPhoto.height !== null ? teamPhoto.height : imgRect.height;
        const x1 = parseFloat(getRandomFloat(0, windowSize.current.width - (width * scale)));
        const y1 = parseFloat(getRandomFloat(0, windowSize.current.height - (height * scale)));

        teamPhoto.placed = true;
        teamPhoto.width = width;
        teamPhoto.height = height;
        teamPhoto.scale = scale;
        teamPhoto.x1 = x1;
        teamPhoto.y1 = y1;
        teamPhoto.x2 = x1 + (width * scale);
        teamPhoto.y2 = y1 + (height * scale);

        for (let bTeamPhoto of teamPhotosRef.current) {
            if (teamPhoto === bTeamPhoto || !bTeamPhoto.placed) {
                continue;
            }

            if (intersects(teamPhoto, bTeamPhoto)) {
                teamPhoto.placed = false;
                break;
            }
        }

        if (teamPhoto.placed) {
            // Add here is left

            const nameContainerTarget = imgRef.current.getElementsByClassName('c-team-overview--photo-name-container')[0];
            if(nameContainerTarget) {
                nameContainerTarget.classList.remove('is-left');
                nameContainerTarget.classList.remove('is-bottom');

                if (teamPhoto.x1 / window.innerWidth < 0.3 &&
                    !nameContainerTarget.classList.contains('is-left')) {
                    nameContainerTarget.classList.add('is-left')
                }

                if (teamPhoto.y1 / window.innerHeight > 0.6 &&
                    !nameContainerTarget.classList.contains('is-bottom')) {
                    nameContainerTarget.classList.add('is-bottom')
                }
            }

            animateExplodeTeamPhoto(teamPhoto)
        } else {
            placeTeamPhoto(teamPhoto, stackSize + 1);
        }
    };

    const explodeOrImplodeImages = () => {
        setExplodeOrImplode(explodeOrImplode.current === EXPLODE ? IMPLODE : EXPLODE);
        if (explodeOrImplode.current === EXPLODE) {
            for (let teamPhoto of teamPhotosRef.current) {
                placeTeamPhoto(teamPhoto)
            }
        } else {
            teamPhotosRef.current.map((teamPhoto) => {
                const imgRef = teamPhoto.ref;
                if (!imgRef || !imgRef.current) return;
                const imgRect = imgRef.current.getBoundingClientRect();

                imgRef.current.getElementsByClassName('c-team-overview--photo-name-container')[0]
                    .classList.remove('active')

                gsap.killTweensOf(imgRef.current);
                gsap.timeline()
                    .set(imgRef.current, {}, 0)
                    .to(imgRef.current, {
                        duration: 0.8,
                        ease: Power1.easeOut,
                        opacity: 0
                    }, 0)
                    .to(imgRef.current, {
                        duration: 1,
                        ease: Quint.easeOut,
                        x: `+=${getRandomFloat(-1*(windowSize.current.width + imgRect.width/2), windowSize.current.width + imgRect.width/2)}`,
                        y: `+=${getRandomFloat(-1*(windowSize.current.height + imgRect.height/2), windowSize.current.height + imgRect.height/2)}`
                        // rotation: getRandomFloat(-40,40)
                    }, 0);
            })
        }
    };

    const showNextImage = () => {
        const teamPhoto = teamPhotosRef.current[imgPosition.current];
        const imgRef = teamPhoto.ref;

        if (!imgRef || !imgRef.current) return;
        // const imgRect = imgRef.current.getBoundingClientRect();
        // img.setRatio();

        const width = teamPhoto.width;
        const height = teamPhoto.height;

        gsap.killTweensOf(imgRef.current);
        gsap.timeline()
            .set(imgRef.current, {
                startAt: {opacity: 0},
                opacity: 1,
                rotation: 0,
                zIndex: zIndexVal.current,
                width: width,
                height: height,
                x: cacheMousePosition.current.x - width/2,
                y: cacheMousePosition.current.y - height/2,
                scale: 1
            }, 0)
            .to(imgRef.current, {
                duration: 1.6,
                ease: Expo.easeOut,
                x: mousePosition.current.x - width/2,
                y: mousePosition.current.y - height/2
            }, 0);

        gsap.timeline()
            .delay(0.6)
            .to(imgRef.current, {
                duration: 0.8,
                ease: Power1.easeOut,
                opacity: 0
            }, 0.6)
            .to(imgRef.current, {
                duration: 1,
                ease: Quint.easeOut,
                x: `+=${getRandomFloat(-1*(windowSize.current.width + width/2), windowSize.current.width + width/2)}`,
                y: `+=${getRandomFloat(-1*(windowSize.current.height + height/2), windowSize.current.height + height/2)}`
                // rotation: getRandomFloat(-40,40)
            }, 0.6);
    }

    const goToTeamMember = () => {
        if (explodeOrImplode.current !== IMPLODE) return;

        const currentMousePosition = mousePosition.current;
        teamPhotosRef.current.map((teamPhoto) => {
            const imageRef = teamPhoto.ref;
            if (!imageRef || !imageRef.current) return;

            const imageRect = imageRef.current.getBoundingClientRect();
            if ((currentMousePosition.x >= imageRect.x && currentMousePosition.x <= (imageRect.x + imageRect.width))
                && (currentMousePosition.y >= imageRect.y && currentMousePosition.y <= (imageRect.y + imageRect.height))) {

                setPreventHover(true);
                gsap.killTweensOf(imageRef.current);
                gsap.timeline()
                    // .set(imageRef.current, {})
                    .to(imageRef.current, {
                        duration: 0.4,
                        ease: Power1.easeOut,
                        opacity: 1,
                        x: windowSize.current.width/2 - teamPhoto.width/2,
                        y: windowSize.current.height/2 - teamPhoto.height/2,
                        // transform: 'translateX(0) translateY(15vh)',
                        width: teamPhoto.width,
                        height: teamPhoto.height,
                        scale: 1
                    })
                    .to(heroTextRef.current, {
                        opacity: 0,
                        duration: 0.2
                    }, 0)
                    .to('.c-team-overview--photo-name-container', {
                        opacity: 0,
                        duration: 0.2
                    },0)
                    .eventCallback('onComplete', () => {

                        trigger('cursor:state', Cursor.THUNDER);

                        navigate(`/team/${teamPhoto.slug}`, {
                            state: {
                                teamPhoto: {
                                    width: teamPhoto.width,
                                    height: teamPhoto.height
                                },
                                fromTeamOverview: false
                            }
                        })
                    });

            }
        });
    }

    const handleClickEvent = () => {
        if (isTabletOrMobileRef.current) return
        explodeOrImplodeImages();
        goToTeamMember();
    }

    // useEffect(() => {
    //     if (navigateState === null || typeof navigateState.url === 'undefined') return;
    // }, [navigateState])

    useEffect(() => {
        if (!isTabletOrMobile && isTabletOrMobileRef.current) {
            isTabletOrMobileRef.current = isTabletOrMobile;
            cancelAnimationFrame(requestRef.current)
            requestRef.current = requestAnimationFrame(render);
        } else {
            isTabletOrMobileRef.current = isTabletOrMobile;
        }
    }, [isTabletOrMobile])

    useEffect(() => {

        const newTeamPhotos = [];
        teamMembers.map((teamMember) => {
            if (!teamMember.profilePicture || !teamMember.profilePicture.gatsbyImageData) return;

            newTeamPhotos.push({
                src: teamMember.profilePicture.gatsbyImageData.images.fallback.src,
                ref: createRef(),
                placed: false,
                width: teamMember.profilePicture.gatsbyImageData.width,
                height: teamMember.profilePicture.gatsbyImageData.height,
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0,
                slug: teamMember.slug,
                firstname: teamMember.firstname,
                jobTitle: teamMember['function']
            } as TeamPhoto)
        });

        setTeamPhotos(newTeamPhotos);

        const calculateWindowSize = () => setWindowSize({width: window.innerWidth, height: window.innerHeight});
        calculateWindowSize();

        window.addEventListener('resize', calculateWindowSize);

        // get the mouse position
        const updateMousePosition = (ev: MouseEvent) => {
            trigger('cursor:click');
            trigger('cursor:state', Cursor.CLICK);
            setMousePosition({x: ev.pageX, y: ev.pageY});
        };

        window.addEventListener('mousemove', updateMousePosition);
        window.addEventListener('click', handleClickEvent);

        cancelAnimationFrame(requestRef.current)
        requestRef.current = requestAnimationFrame(render);

        // On component unmount
        return () => {
            window.removeEventListener('click', handleClickEvent);
            window.removeEventListener('resize', calculateWindowSize);
            window.removeEventListener('mousemove', updateMousePosition);
            cancelAnimationFrame(requestRef.current)
        }
    }, [])


    const hidePage = () => {
        Fade.animate([
            { target: '.o-layout', type: Fade.TYPE_OPACITY }
        ])
    };

    useEffect(() => {
        on('page:out', hidePage)

        return () => {
            off('page:out', hidePage)
        }
    }, []);


    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {setTimeout(() => {setIsLoaded(true)}, 100)}, []);

    return (
        <>
            <Logo />
            <Navigation />
            <Helmet>
                <title>{getPageTitle({title: `Weekenders`})}</title>
                <style>
                    {`body { background-color: ${isTabletOrMobile ? '#FFFFFF' : '#5FE7FF'}; }`}
                </style>
            </Helmet>
            <Breadcrumb text={"Weekenders"} />

            <div className={`${isLoaded ? 'e-active--delay' : ''} o-layout o-layout--full-page u-columns-24 c-team-overview--hero u-visually-hidden@until-lap`}>
                <div className={"o-layout--full-page c-team-overview--hero_text"} ref={heroTextRef}>
                    <h1 className={"u-font-family--primary u-text-color--red c-team-overview--hero_title e-effect e-fade e-move--up-gentle"}>Meet the team</h1>
                </div>

                <div className={"c-team-overview--photogrid o-layout--full-page"}>
                    {teamPhotos.map((teamPhoto, index) => (
                        <div className={"c-team-overview--photo"}  ref={teamPhoto.ref} key={`team-photo_${index}`}
                             onMouseEnter={() => {
                                 if (explodeOrImplode.current !== EXPLODE) return
                                 trigger('cursor:click');
                             }}
                             onMouseOut={() => {
                                 if (explodeOrImplode.current !== EXPLODE) return
                                 trigger('cursor:unclick');
                             }}>
                            <div className={"c-team-overview--photo-name-container"}>
                                <div className={"c-team-overview--photo-line"}><div className={"c-team-overview--photo-name"}>{teamPhoto.firstname}</div></div>
                                <div className={"c-team-overview--photo-line"}><div className={"c-team-overview--photo-title"}>{teamPhoto.jobTitle}</div></div>
                            </div>
                            <Tilt trackOnWindow={true} reset={false} tiltReverse={true}>
                            <img src={teamPhoto.src} alt={""}  />
                            </Tilt>
                        </div>
                    ))}
                </div>
            </div>

            <div className={`${isLoaded ? 'e-active--delay' : ''} o-layout u-columns-24 c-team-overview--mobile u-visually-hidden@lap u-mob--bottom-margin__default `}>
                <div className={"u-colspan-24 c-breadcrumb"}/>
                {teamPhotos.map((teamPhoto, index) => (
                    <Link to={`/team/${teamPhoto.slug}`}  key={index} className={`u-colspan-24 o-layout u-columns-24 c-team-overview--mobile-member ${index%2 ? 'uneven' : '' }`}>
                        <div className={"u-colspan-6 c-team-overview--mobile-photo"}><img src={teamPhoto.src} alt={""} /></div>
                        <div className={"u-colspan-18 c-team-overview--mobile-details"}>
                            <div className={"c-team-overview--mobile-member-name c-team-member--name"}><span>{teamPhoto.firstname}</span></div>
                            <div className={"c-team-overview--mobile-member-job-title"}>{teamPhoto.jobTitle}</div>
                        </div>
                    </Link>
                ))}
            </div>
        </>
    )
};

export default TeamOverviewTemplate;
