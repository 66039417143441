import * as React from "react";
import {useRef} from "react";

export const createRefAndSetFunction = (initialValue: any) : [React.MutableRefObject<any>, any] => {
    const ref = useRef(initialValue);
    const refSetFunc = (data) => {
        if (typeof ref === 'undefined' || typeof ref.current === 'undefined') return;
        ref.current = data;
    }

    return [ref, refSetFunc]
};

